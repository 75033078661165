.bg-dd-privato {
  background-color: #f6f6f6ff; /* Esempio di colore blu */
}


.bg-dd-impresa {
  background-color: #f6f6f6ff; /* Esempio di colore arancione */
}

.privato {
  color: #002864; 
}


.impresa {
  color: #FB8500; 
}

.bg-canvas-impresa
{
  background-color: #FEDCB5;
}
.bg-canvas-privato
{
  background-color: #B6C9E5;
}

.header-privato {
  background-color: #b6c9e5ff; /* Esempio di colore blu */
}


.header-impresa {
  background-color: #fedcb5ff; /* Esempio di colore arancione */
}


.bg-privato {
  background-color: #002864; /* Esempio di colore blu */
}


.bg-impresa {
  background-color: #FB8500; /* Esempio di colore arancione */
}


.App {
  text-align: center;
  
}
.container {
  position: relative; 
}
.fixed-div {
  position: absolute; 
  top: 10px; 
  left: 10px; 
  background-color: #f0f0f0;
  padding: 5px;
  border: 1px solid #ccc;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.left-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  background-color: #fff; /* Colore di sfondo */
  border-right: 1px solid #ccc; /* Aggiunge un bordo a destra per separarlo dal contenuto principale */
  overflow-y: auto; /* Abilita lo scrolling se il contenuto è più grande della dimensione del componente */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.my-custom-scrollbar {
  width: 25%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin; /* Per Firefox */
  scrollbar-color: #666 #f1f1f1; /* Per Firefox */
}

.my-custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.my-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.my-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.my-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.scroll-to-bottom-container {
  position: absolute;
  top: 20%; right :5%;
  
}
.scroll-container {
  position: relative;
  height: 100%; /* Altezza del contenitore */
  overflow-y: auto; /* Abilita la scrollbar verticale */
  border: 1px solid #ccc; /* Aggiungi un bordo per chiarezza */
}

.scroll-button {
  position: absolute;
  width: 40px; /* Larghezza dei pulsanti */
  height: 40px; /* Altezza dei pulsanti */
  border: none;
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
}

.scroll-top {
  top: 0;
}

.scroll-bottom {
  bottom: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
